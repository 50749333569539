<template>
    <el-col
        v-show="conf.show"
        class="comps-group-item"
        :class="activeData.field == conf.field ? 'comps-group-item-selected' : ''"
        :ref="conf.field"
        :sm="24"
        :md="24"
        @click.native.stop="drawItemClick(conf)"
    >
        <draggable class="comps-group-draggable" :list="drawList" :animation="340" :group="conf.field">
            <template v-for="(item, index) in drawList">
                <el-col
                    :key="index"
                    v-show="item.show"
                    class="comps-group-sub-item"
                    :class="activeData.field == item.field ? 'comps-group-sub-item-selected' : ''"
                    :ref="item.field"
                    :sm="24"
                    :md="item.span || 24"
                    @click.native.stop="drawItemClick(item)"
                >
                    <AddressComp v-if="item.type == 'address'" :conf="item" />
                    <CompItem v-else :conf="item" @chooseValue="handleChooseValue" />
                    <img v-if="activeData.field == item.field" class="comps-group-item-del" :src="del" alt="" @click.stop="drawItemDel(item, index)" />
                </el-col>
            </template>
        </draggable>
        <img v-if="activeData.field == conf.field" class="comps-group-item-del" :src="del" alt="" @click.stop="drawItemDel(conf)" />
    </el-col>
</template>

<script>
import draggable from "vuedraggable";
import CompItem from "./CompItem.vue";
import AddressComp from "./AddressComp.vue";

export default {
    name: 'CompsGroup',
    components: {
        draggable,
        CompItem,
        AddressComp
    },
    props: [
        'conf',
        'activeData'
    ],
    data() {
        return {
            drawList: []
        }
    },
    filters: {},
    computed: {
        del() {
            return require("@/assets/images/stuInfoTemplate/del2.png");
        },
    },
    watch: {
        conf: {
            handler(nVal, oVal) {
                console.log('[表单组组件][watch][conf]nVal.subComps:', nVal.subComps)
                this.drawList = nVal.subComps
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    updated() {},
    beforeDestroy() {},
    methods: {
        drawItemClick(item) {
            this.$emit('itemClick', item)
        },
        drawItemDel(item, index) {
            this.$emit('itemDel', item, index)
        },
        handleChooseValue(comp, val) {
            this.$emit('chooseValue', comp, val)
        },
    }
}
</script>

<style lang="scss" scoped>
.comps-group-item {
    position: relative;
    cursor: move;
    box-sizing: border-box;
    padding: 22px 30px;
    border: 1px dashed #c7c7cc;
    border-radius: 4px;
}
.comps-group-item-selected {
    box-shadow: 0px 5px 20px 0px #f0f2f5;
    border-width: 1px 1px 1px 4px;
    border-style: dashed dashed dashed solid;
    border-color: #3C7FFF;
}
.comps-group-item-del {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.comps-group-sub-item {
    position: relative;
    cursor: move;
    box-sizing: border-box;
    padding: 16px 16px;

    ::v-deep .el-form-item {
        margin-bottom: 0;

        .el-form-item__label {
            word-wrap: break-word;
        }
    }

    // 预览-表单组-字段说明
    ::v-deep .explainText {
        // position: absolute;
        // bottom: -25px;
        // left: 0px;
        font-size: 12px;
        color: #dd6161;
    }

    // 预览-表单组-分割线
    .divider {
        position: relative;
        border-top: 1px solid #dcdfe6;

        ::v-deep .divider-title {
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 20px;
            font-size: 16px;
        }
    }
}
.comps-group-sub-item-selected {
    box-shadow: 0px 5px 20px 0px #f0f2f5;
    border-left: 4px solid #3C7FFF;
    // background: #c7c7cc;

    .comps-group-item-del {
        top: 10px;
        right: 10px;
    }
}
</style>

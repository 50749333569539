<template>
    <div class="address-comp">
        <el-form-item :label="conf.label" :required="conf.required">
            <el-cascader
                style="flex: 1;width: 100%"
                :key="conf.field + conf.addressLevel + conf.startAddressLevel + conf.startAddress.join('')"
                :value="conf.defaultValue"
                :props="addressProps"
                :placeholder="conf.placeholder"
                clearable
                @input="setValue"
            />
            <div class="explainText">{{conf.explain}}</div>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'AddressComp',
    components: {},
    props: ["conf"],
    data() {
        return {
        }
    },
    filters: {},
    computed: {
        addressProps() {
            let _this = this
            return {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    let parentCode = 0; // 第一级code为0
                    if (_this.conf.startAddress?.length > 0) { // 有起始是地址取其code
                        parentCode = _this.conf.startAddress[_this.conf.startAddress.length - 1].split('-')[0]
                    }
                    if (level > 0) { // 不是第一级直接取第一级的code
                        parentCode = node.data.code;
                    }
                    let endAddressLevel = _this.conf.addressLevel - (_this.conf.startAddressLevel - 1)
                    console.log(
                        "[地址选择组件][addressProps]parentCode:", parentCode,
                        "addressLevel:", _this.conf.addressLevel,
                        "startAddressLevel:", _this.conf.startAddressLevel,
                        "endAddressLevel:", endAddressLevel,
                        "node.level:", node.level
                    )
                    _this._get(`/operate/common/getAreaList?parentCode=${parentCode}`).then((res) => {
                        // console.log("[地址选择组件][addressProps]地址列表：", res);
                        if(res.data.code == '200') {
                            const nodes = res.data.data.map((item) => {
                                return {
                                    code: item.areaCode,
                                    value: `${item.areaCode}-${item.areaName}`,
                                    label: item.areaName,
                                    leaf: level + 1 >= endAddressLevel
                                };
                            });
                            resolve(nodes);
                        }
                    })
                },
            }
        },
    },
    watch: {},
    created() {
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        setValue(event) {
            this.conf.defaultValue = event
        },
    }
}
</script>

<style lang="scss" scoped>
</style>

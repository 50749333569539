<script>
const components = {
    // 单行文本
    input(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <div style="display: flex;flex-direction: row;width: 100%;">
                    {
                        conf.rules == 'document' && conf.documentOptions.length > 0 ?
                        <el-select
                            style="width:150px;"
                            value={conf.documentDefaultValue}
                            placeholder="证件类型"
                            onInput={(event) => {
                                conf.documentDefaultValue = event
                            }}
                        >
                            { conf.documentOptions.map((ele) => (<el-option label={ele.label} value={ele.value} disabled={false}></el-option>)) }
                        </el-select>
                        : null
                    }
                    <el-input
                        style="flex: 1;"
                        value={conf.defaultValue}
                        maxlength={conf.maxLength}
                        placeholder={conf.placeholder}
                        clearable
                        onInput={(event) => {
                            conf.defaultValue = event
                        }}
                    />
                </div>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 多行文本
    textarea(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-input
                    key={conf.field + conf.minRows + conf.maxRows}
                    type='textarea'
                    value={conf.defaultValue}
                    autosize={{
                        minRows: conf.minRows,
                        maxRows: conf.maxRows
                    }}
                    maxlength={conf.maxLength}
                    placeholder={conf.placeholder}
                    onInput={(event) => {
                        conf.defaultValue = event
                    }}
                />
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 分割线
    divider(h, conf) {
        return (
            <div class={'divider'}>
                <div class={'divider-title'}>{conf.label}</div>
            </div>
        )
    },
    // 下拉选择
    select(h, conf) {
        const { chooseValue } = this.$listeners
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-select
                    style='width:100%;'
                    value={conf.defaultValue}
                    placeholder={conf.placeholder}
                    clearable
                    onInput={(event) => {
                        conf.defaultValue = event
                        chooseValue(conf, event)
                    }}
                    multiple={conf.multiple}
                >
                    {conf.options.map((ele) => (
                        <el-option label={ele.label} value={ele.value}></el-option>
                    ))}
                </el-select>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 单选框组
    radio(h, conf) {
        const { chooseValue } = this.$listeners
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-radio-group
                    value={conf.defaultValue}
                    onInput={(event) => {
                        conf.defaultValue = event
                        chooseValue(conf, event)
                    }}
                >
                    {conf.options.map((ele) => (
                        <el-radio label={ele.value}>{ele.label}</el-radio>
                    ))}
                </el-radio-group>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 多选框组
    checkbox(h, conf) {
        const { chooseValue } = this.$listeners
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-checkbox-group
                    value={conf.defaultValue}
                    onInput={(event) => {
                        conf.defaultValue = event
                        chooseValue(conf, event)
                    }}
                >
                    {conf.options.map((ele) => (
                        <el-checkbox label={ele.value}>{ele.label}</el-checkbox>
                    ))}
                </el-checkbox-group>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 单图片上传
    upload(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-upload class='avatar-uploader' action='' show-file-list={false} disabled>
                    <i class='el-icon-plus avatar-uploader-icon'></i>
                </el-upload>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 多图片上传
    miupload(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-upload class='avatar-uploader' action='' show-file-list={true} list-type='picture-card' disabled>
                    <i class="el-icon-plus"></i>
                </el-upload>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 附件上传
    fileload(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-upload action='' multiple>
                    <el-button size="small" type="primary">{conf.placeholder}</el-button>
                </el-upload>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 日期选择
    date(h, conf) {
        const { chooseValue } = this.$listeners
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <el-date-picker
                    style="width: 100%"
                    value={conf.defaultValue}
                    placeholder={conf.placeholder}
                    type="date"
                    format={conf.format ? conf.format : "yyyy-MM-dd"}
                    value-format={conf.format ? conf.format : "yyyy-MM-dd"}
                    onInput={(event) => {
                        conf.defaultValue = event
                        chooseValue(conf, event)
                    }}
                />
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
    // 文字说明
    explain(h, conf) {
        const textStyle = `display:block;width:100%;font-size:${conf.fontSize}px;color:${conf.fontColor};font-weight:${conf.fontWeight};font-style:${conf.fontStyle};text-decoration:${conf.textDecoration};text-align:${conf.textAlign};`
        return (
            <el-form-item>
                <span class={'explain'} style={textStyle}>
                    {conf.placeholder}
                </span>
            </el-form-item>
        )
    },
    // 图片示例
    imgExample(h, conf) {
        return (
            <el-form-item label={conf.label} required={conf.required}>
                <img src={conf.placeholder}  style="flex: 1; width:100%;"></img>
                <div class="explainText">{conf.explain}</div>
            </el-form-item>
        )
    },
}

function compIsNotFound(vm) {
    throw new Error(`没有与${vm.conf.type}匹配的layout`);
}

export default {
    props: {
        conf: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },
    computed: {},
    methods: {},
    render(h) {
        if(!this.conf.type) {
            console.log('未找到对应type', this.conf.type)
            return
        }

        let comp = components[this.conf.type]
        // console.log("[render]conf:",this.conf)
        if (comp) {
            return comp.call(this, h, this.conf)
        } else {
            return compIsNotFound(this)
        }
    }
}
</script>
<style lang="scss" scoped>
    .avatar-uploader {

        // el-upload 修改修改样式
        /deep/ .el-upload--picture-card {
            width: 80px !important;
            height: 80px;
            line-height: 90px;
            background-color: #FFFFFF;
        }
        /deep/ .avatar-uploader-icon {
            width: 80px !important;
            height: 80px !important;
            line-height: 80px !important;
            background-color: #FFFFFF;
        }
    }
</style>
